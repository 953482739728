import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ConfirmLayout from 'src/templates/confirm'
import PageHead from 'src/components/head/PageHead'

import { getUrlParam } from 'src/utils'

// Styles & Images
import SecuritizeIcon from 'static/confirm/images/securitize--icon.svg'
import { BROWSER_EXTENSION_BUILDS, REFERRALS_URL } from 'src/constants'

// Main component:
const ReferralsRedirectPage = ({ location }) => {
  const origin = useMemo(() => getUrlParam('origin'), [])
  const cause = useMemo(() => getUrlParam('cause'), [])
  const build = useMemo(() => getUrlParam('build'), [])

  let deepLinkPath, redirectUrl
  if (BROWSER_EXTENSION_BUILDS.includes(build)) {
    const dashboardUrl = new URL(REFERRALS_URL)
    dashboardUrl.searchParams.append('origin', origin)
    redirectUrl = dashboardUrl.toString()
  } else {
    deepLinkPath = `referrals${origin ? `/${origin}` : ''}`
  }

  return (
    <ConfirmLayout
      icon={SecuritizeIcon}
      page="referrals-redirect"
      deepLinkPath={deepLinkPath}
      redirectUrl={redirectUrl}
      location={location}
      section={cause}
    />
  )
}

// Main component props:
ReferralsRedirectPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

ReferralsRedirectPage.defaultProps = {}

export default ReferralsRedirectPage

// <head> component:
export function Head() {
  return <PageHead page="referrals-redirect" noindex />
}
